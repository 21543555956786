<template>
  <dashPage outlined  >

    <template #default>

      <!-- TOPIC TITLE -->
      <div class="d-flex align-center">
        <v-btn :to="businessDashLink('news')" icon :color="wsACCENT"><v-icon>mdi-chevron-left</v-icon></v-btn>
        <div style="width: 100%"  class="d-flex justify-space-between align-center px-8  pb-0 wsRounded"  >
          <div style="width: 100%">
            <!-- News Name-->
            <wsEditorSingleLine output-format="text"
                                :placeholder="$t('NewsName')"
                                :editorStyle="lectureTopicStyle"
                                v-model="entity.title[selectedLang]"/>
          </div>
          <!-- Language Switch-->
          <div class="d-flex">
            <v-chip @click=" checkLectureNameTranslation(lang); selectedLang = lang;"  v-for="(lang) in lang" :key="lang"
                    class="mr-1 mt-2"
                    :outlined="selectedLang !== lang"
                    small >
              <!--      :color="text[lang] ? wsDARK : 'grey'" dark-->
              <h4>{{ lang }} </h4>
            </v-chip>
            <v-menu v-if="remainedLanguages.length > 0" offset-y transition="slide-y-transition">

              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on"
                       class="noCaps mt-2 px-0"
                       :color="wsDARK"
                       text  small >
          <span>
            <v-icon small>mdi-plus</v-icon>
            {{ $t('AddTranslation') }} </span>
                </v-btn>

              </template>
              <v-list dense>
                <v-list-item-group>
                  <v-list-item @click="addTranslation(lang)" v-for="lang in remainedLanguages" :key="lang">
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ lang }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-menu>
          </div>
        </div>
      </div>

      <!-- CONTENT -->
      <v-sheet  class="pa-3 wsRoundedHalf mt-6">
        <wsEditorRegular @save="saveNews" v-model="entity.content[selectedLang]" />
      </v-sheet>
    </template>

    <template #side>

      <v-btn v-if="!entity.published" @click="publish" class="noCaps" :color="wsACCENT" dark elevation="0"
             light block >
        <v-icon small></v-icon>{{ $t('Publish')}}</v-btn>

      <div class="d-flex justify-space-between align-center mt-6 mb-3">
        <h5>{{$t('NewsEditor')}}</h5>
          <v-btn @click="saveNews"
                 class="noCaps"
                 small
                 outlined>
            <v-icon small></v-icon>{{ $t('Save')}}</v-btn>
      </div>
      <div class="d-flex justify-space-between align-center mb-6">
        <h5>{{$t('Status')}}</h5>
        <h5>{{ $t('Draft') }}</h5>
      </div>

      <v-fade-transition mode="out-in">

        <v-sheet v-if="entity.img && !displayUpload "
                 @click="displayUpload = true"
                 light class="wsRoundedHalf-2 py-2 px-2">
          <v-img v-if="entity.img" class="wsRoundedHalf" aspect-ratio="1.4" :src="entity.img"></v-img>
          <v-icon v-else size="80">mdi-account</v-icon>
        </v-sheet>



        <div v-if="!entity.img || displayUpload">
          <wsImageUpload @upload="uploadImage" />
          <v-btn v-if="entity.img" @click="displayUpload = false" block text class="noCaps" small>{{$t('Back')}}</v-btn>
        </div>

      </v-fade-transition>

      <div class="mt-6">
        <ws-date-picker v-model="entity.date" :label="$t('Date')" />
      </div>

      <v-text-field class="mt-3" outlined dense hide-details v-model="entity.alias" :label="$t('Link')" placeholder="News Link" />


    </template>


  </dashPage>

</template>

<script>
import {mapActions} from "vuex";
import wsEditorRegular from "@/components/UI/wsEditorRegular";
import wsEditorSingleLine from "@/components/UI/wsEditorSingleLine";
import wsImageUpload from "@/components/UI/wsImageUpload";


export default {
  name: "editNews",
  props : ['uuid'],
  components : {
    wsEditorRegular,
    wsEditorSingleLine,
    wsImageUpload
  },
  data() {
    return {
      displayUpload : false,
      entity : {
        title : {},
        content : {}
      },

      lectureNameStyle :
          '  font-family: \'Montserrat\', Helvetica, Arial, sans-serif;\n' +
          '  font-size: 24px;\n' +
          '  font-weight: 600;\n' +
          '  width: 100%;\n' +
          '  line-height: 1;\n' +
          '  padding: 5px; padding-bottom :1px ',
      lectureTopicStyle :
          '  font-family: \'Montserrat\', Helvetica, Arial, sans-serif;\n' +
          '  font-size: 18px;\n' +
          '  font-weight: 600;\n' +
          '  width: 100%;\n' +
          '  line-height: 1;\n' +
          '  padding: 5px; padding-bottom :1px ',

      //lang
      selectedLang : 'ua',
      langBase : [],
    }
  },
  computed : {

    // langs
    langDefault() {
      return [process.env.VUE_APP_I18N_LOCALE, process.env.VUE_APP_I18N_FALLBACK_LOCALE, 'ru']
    },
    lang() {
      return [...this.langDefault, ...this.langBase ]
    },
    remainedLanguages(){
      let items = this.PARAMS_languages
      items = items.filter( el=> !this.lang.includes(el) )
      return items
    }

  },
  methods : {
    ...mapActions('news', [
          'GET_ADMIN_BUSINESS_NEWS_ONE',
          'ADD_EDIT_BUSINESS_NEWS',
          'UPLOAD_IMAGE'
        ]
    ),

    async publish() {
      this.entity.published = true
      await this.saveNews()
      this.notify(this.$t('NewsPublished'))
    },
    async saveNews() {
      let result = await this.ADD_EDIT_BUSINESS_NEWS(this.entity)
      if (!result) { return }
      this.notify(this.$t('ChangesSaved'))
    },
    uploadImage(file) {
      this.loading = true
      this.LOADING = true
      return this.UPLOAD_IMAGE({file : file, param : 'news'}).then((out)=>{
        if ( out.result ) {
          this.entity.img = out.data.url
          this.entity = JSON.parse(JSON.stringify(this.entity))
          this.saveNews()
          this.displayUpload = false
        }



        this.$store.state.dashboard.UPLOAD_LOADING = false
        this.loading = true
        this.LOADING = false

        this.uploadLogo = false
      }).catch(()=>{
        this.loading = true
        this.LOADING = false
      })

    },

    addTranslation(lang) {
      this.checkLectureNameTranslation(lang)
      this.langBase.push(lang)
    },
    getTranslation(translations) {
      return translations[this.selectedLang] ? translations[this.selectedLang] :  this.$t('NoTranslation')
    },
    checkLectureNameTranslation(lang) {

      if (!this.entity.title[lang]) {
          this.entity.title[lang] = '' }

      if (!this.entity.content[lang]) {
        this.entity.content[lang] = '' }

      this.entity = JSON.parse(JSON.stringify(this.entity))
    },

  },
  async mounted(){
    if ( !this.uuid ) { return }
    this.entity = await this.GET_ADMIN_BUSINESS_NEWS_ONE(this.uuid)
  }
}
</script>

<style scoped>

</style>